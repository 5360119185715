import Layout from "../components/Layout";

const press = [
  {
    image:
      "https://foxinterviewer.com/wp-content/uploads/2022/04/fox-scg-logo.svg",
    source: "FoxInterviewer.com",
    title: "Fox Interviewer",
    content:
      "Andy Ta Kong: Top mistakes to avoid when starting on your business",
    date: "Jul. 26th 2022",
    link: "https://foxinterviewer.com/people/andy-ta-kong-top-mistakes-to-avoid-when-starting-on-your-business/",
  },
  {
    image:
      "https://futuresharks.com/wp-content/uploads/2017/03/new-future-sharks-logo.png",
    source: "FutureSharks.com",
    title: "Future Shark",
    content:
      "Andy Ta Kong: How to find a technical Co-Founder for your startup ",
    date: "Jul. 4th 2022",
    link: "https://futuresharks.com/andy-ta-kong-how-to-find-a-technical-co-founder-for-your-startup/",
  },
  {
    image:
      "https://www.nasdaq.com/sites/acquia.prod/files/styles/355x355/public/2022/02/14/crunchbase%20logo.png?h=2a479378&itok=-ssSoEZT",
    source: "Crunchbase.com",
    title: "CrunchBase",
    content: "CrunchBase Person Profile",
    date: "Dec. 20th 2021",
    link: "https://www.crunchbase.com/person/andy-ta-kong",
  },
  {
    image: "https://en.wikialpha.org/mediawiki/images/7/7d/WikiAlpha_logo.png",
    source: "Wikialpha.org",
    title: "Wiki Alpha",
    content: "The open encyclopedia and journal",
    date: "Dec. 20th 2021",
    link: "https://en.wikialpha.org/wiki/Andy_Ta_Kong",
  },
  {
    image:
      "https://play-lh.googleusercontent.com/sb30umyAPj9A4ixl4jnPJO15ET-mo4TihKOI0xwFzRbxOfZo6fozeKmAjf8Lhl3mqHY",
    source: "Imdb.com",
    title: "IMBD",
    content: "Authoritative source for movie, TV and celebrity content.",
    date: "Dec. 20th 2021",
    link: "https://www.imdb.com/name/nm12316746/",
  },
  {
    image:
      "https://businessdeccan.com/wp-content/uploads/2019/06/business-deccan-logo.png",
    source: "Businessdeccan.com",
    title: "Business Deccan",
    content:
      "Andy Ta Kong is an inspiring entrepreneur who has dedicated his life to his work.",
    date: "June 23th 2020",
    link: "https://businessdeccan.com/andy-ta-kong-started-his-journey-working-for-his-familys-business/",
  },
  {
    image:
      "https://files.startupranking.com/startup/thumb/557258_9420cb2e5535066937ab9c9c04eda67152ee7080_fact-sider_l.jpeg",
    source: "Wiki.Factsider.com",
    title: "Wiki Factsider",
    content: "Internet Media",
    date: "Dec. 20th 2021",
    link: "https://wiki.factsider.com/andy-ta-kong/",
  },
];

export default function Press() {
  const articles = press.map((article, i) => {
    return (
      <a
        key={i}
        href={article.link}
        className="border border-border-gray flex flex-col 
      md:flex-row duration-200 rounded-sm hover:rounded article"
      >
        <div className="w-28 min-w-[7rem] h-28 relative border border-border-gray bg-white mx-4 mt-4 md:m-6 rounded-sm">
          <img
            src={article.image}
            className="w-full  absolute top-1/2 -translate-y-1/2 duration-200 rounded-sm"
          />
        </div>
        <div className="flex flex-col md:block my-6 ml-5 md:ml-0 grow font-lexend">
          <p className="text-white text-opacity-70 mt-6 md:mt-0 md:mr-5 order-last md:order-none md:float-right">
            Published {article.date}
          </p>
          <p
            style={{ color: "#7B62E2" }}
            className="font-bold text-sm text-opacity-90"
          >
            &lt;
            <span className="text-white mx-[0.625rem] text-base">
              {article.source}
            </span>
            &gt;
          </p>
          <p className="font-bold text-xl mt-1 md:mt-4 text-opacity-90 text-white">
            {article.title}
          </p>
          <p className="font-bold text-lg mt-2 text-opacity-80 text-white">
            {article.content}
          </p>
        </div>
        <div className="md:ml-auto float-right m"></div>
      </a>
    );
  });

  return (
    <Layout>
      <div className="flex flex-col items-center relative  ">
        <h1 className="text-center text-white text-6xl md:text-7xl mt-[8vh]">
          Press<span style={{ color: "#EE533E" }}>.</span>
        </h1>

        <div className="flex flex-col gap-6 mt-12 mx-4 md:mx-0 md:w-9/12 md:overflow-y-auto md:h-[57vh] md:pr-2 custom-scrollbar">
          {articles}
        </div>
      </div>
    </Layout>
  );
}
