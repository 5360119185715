import Layout from "../components/Layout";

const company = [
  {
    name: "BlackWater",
    role: "Founder & CTO",
    image: "/img/blackwater.gif",
    description:
      "BlackWater Group is a crypto mining data center based in Nevada that offers Mining-as-a-Service. It helps clients host, manage & maintain mining and make them successful in the Bitcoin & Cryptocurrency markets.",
    link: "https://www.blackwater.co/",
    linkName: "blackwater.co",
    textColor: "text-lightblue",
    outline: "border-lightblue hover:bg-lightblue hover:text-border-gray",
    icon1: "/img/react.png",
    icon2: "/img/next.png",
    icon3: "/img/node.png",
    icon4: "/img/mongo.png",
    icon5: "/img/express.png",
    icon6: "/img/tailwindcss.png",
    icon7: "/img/aws.png",
  },
  {
    name: "Alqen",
    role: "Founder & Owner",
    image: "/img/alqen.gif",
    description:
      "Alqen.io is an AI-based Ecommerce software that simplify the sellers' tedious day-to-day operations by helping Amazon & Walmart sellers to manage inventory, automate orders, monitor prices, and bulk upload products.",
    link: "https://alqen.io/",
    linkName: "alqen.io",
    textColor: "text-purple",
    outline: "border-purple hover:bg-purple hover:text-border-gray",
    icon1: "/img/vue.png",
    icon2: "/img/nuxt.png",
    icon3: "/img/node.png",
    icon4: "/img/mysql.png",
    icon5: "/img/typescript.png",
    icon6: "/img/aws.png",
    icon7: "/img/lamda.png",
  },

  {
    name: "Project Wifi",
    role: "Founder & COO/CTO",
    image: "/img/projectwifi.gif",
    description:
      "Project WIFI is an eCommerce management firm specializing in fully comprehensive (A-Z) services by selling on Amazon, Walmart, and Facebook. Project WIFI ensures a fully functional eCommerce business for clients from creation to scaling.",
    link: "https://projectwifi.io/",
    linkName: "projectwifi.io",
    textColor: "text-orange",
    outline: "border-orange hover:bg-orange hover:text-border-gray",
    icon1: "/img/vue.png",
    icon2: "/img/nuxt.png",
    icon3: "/img/tailwindcss.png",
    icon4: "/img/node.png",
    icon5: "/img/mysql.png",
    icon6: "/img/adonis.png",
  },
  {
    name: "NuclearNode",
    role: "Owner & COO",
    image: "/img/nuclearnode.gif",
    description:
      "Founded by two 8th graders, Andy & Brandon, NuclearNode was a hosting-based startup company providing gamers with the highest quality hardware and service at a low cost. Shockbyte later acquired the company.",
    link: "https://shockbyte.com/",
    linkName: "nuclearnode.com",
    textColor: "text-yellow",
    outline: "border-yellow hover:bg-yellow hover:text-border-gray",
    icon1: "/img/mysql.png",
  },
];

export default function projects() {
  const companies = company.map((newCard) => {
    const redirectURL = () => {
      window.location.href = `${newCard.link}`;
    };
    // const redirectURLTwo = () => {
    //   window.location.href = `${newCard.linkTwo}`;
    // };

    return (
      <div className="flex flex-col p-4 border-2 border-border-gray justify-between">
        <img className="" src={newCard.image} alt="#" />
        <hr className="my-4 border-t-2 border-dashed border-border-gray" />

        <div className="flex flex-row justify-between">
          <div>
            <h1 className="text-white">{newCard.name}</h1>
            <p className={`font-lexend ${newCard.textColor}`}>{newCard.role}</p>
          </div>

          <button
            className={`text-white text-sm border-box border-2 px-4 py-2 font-lexend ${newCard.outline}`}
            type="button"
            onClick={redirectURL}
          >
            Visit Site
          </button>
        </div>
        <hr className="my-4 border-t-2 border-dashed border-border-gray" />
        <div className="box-content">
          <p className="text-border-gray font-lexend text-gray-400">
            {newCard.description}
          </p>
        </div>
      </div>
    );
  });

  return (
    <Layout>
      <h1 className="text-center text-white text-5xl md:text-6xl mt-[5vh] mb-[4vh]">
        Portfolio <span className="text-gold">.</span>
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-base-container w-10/12 md:w-11/12 mx-auto gap-10">
        {companies}
      </div>
    </Layout>
  );
}
