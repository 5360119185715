export default function ColorPallet(props) {
    let listedItems = props.colors.map((color, i) => (
        <div className='w-[calc(25%-0.4rem)] xl:w-24' key={i}>
            <div style={{ backgroundColor: color }} className='h-5'></div>
            <p className='text-white text-center mt-2 text-sm font-bold mb-1'>{color}</p>
        </div>
    ));

    return (
        <div className='font-mono m-5 border-white sm:w-2/5 xl:w-auto'>
            <p style={{ color: '#EE533E' }} className='font-bold text-lg ml-1 mb-5 text-opacity-90'>
                &lt;<span className='text-white mx-3 text-xl'>Color Pallet</span>&gt;
            </p>
            <div className='flex flex-row gap-2 xl:gap-3 row-2 flex-wrap'>{listedItems}</div>
        </div>
    );
}
