

export default function Decorate() {
    return <div className="hidden xl:block">
        <img className="absolute left-0 top-0 -translate-x-1/2 -translate-y-1/2" src="/icons/bigSquare.svg"/>
        <img className="absolute left-0 bottom-0 -translate-x-1/2 translate-y-1/2" src="/icons/bigSquare.svg"/>
        <img className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2" src="/icons/bigSquare.svg"/>
        <img className="absolute right-0 bottom-0 translate-x-1/2 translate-y-1/2" src="/icons/bigSquare.svg"/>
        <img className="absolute right-0 bottom-1/2 translate-x-1/2 translate-y-1/2" src="/icons/smallSquare.svg"/>
        <img className="absolute left-0 bottom-1/2 -translate-x-1/2 translate-y-1/2" src="/icons/smallSquare.svg"/>
        <img className="absolute right-1/2 bottom-0 translate-x-1/2 translate-y-1/2" src="/icons/smallSquare.svg"/>
        <img className="absolute right-1/2 top-0 translate-x-1/2 -translate-y-1/2" src="/icons/smallSquare.svg"/>
    </div>
}