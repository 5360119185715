import Form from "../components/form";
import Layout from "../components/Layout";
import emailjs from "emailjs-com";
import React, { useRef } from "react";

export default function Contact() {
  function sendEmail(e) {
    emailjs
      .sendForm(
        "service_f572afq",
        "template_3qai2no",
        e.target,
        "Z4XacrKfbD7WwE7Pb"
      )
      .then((res) => {
        console.log(res.text);
      })
      .catch((err) => console.log(err.text));
  }

  return (
    <Layout>
      <div className="flex flex-col max-w-base-container w-11/12 mx-auto  my-16 ">
        <p className="text-white text-5xl md:text-6xl text-center">
          Contact<span style={{ color: "#7B62E2" }}>.</span>
        </p>
        {/* <div className="">
            <p className="text-white text-xl font-bold mt-8 lg:mt-0">
              <span style={{ color: "#7B62E2" }}>/*</span> Get in touch
            </p>
            <br />
            <p className="text-white text-lg font-lexend">
              I like writing scripts to solve problems, enjoying the risk-taking
              lifestyle of an entrepreneur.
            </p>
          </div> */}

        {/* <div className="w-10/12 border-t-2 border-border-gray border-dashed border-opacity-90" /> */}
        <div className="flex flex-col items-center  w-full pt-16">
          <p
            style={{ color: "#3EAEEE" }}
            className="text-center md:text-left font-mono font-bold text-lg ml-1 mb-5 text-opacity-90"
          >
            &lt;
            <span className="text-white text-center mx-3 text-xl ">
              Contact Form
            </span>
            &gt;
          </p>
          <form
            className="grid lg:grid-cols-2 grid-cols-1 text-white gap-x-10 gap-y-4 justify-center w-full"
            onSubmit={sendEmail}
          >
            <Form type="text" name="name" placeholder="Full Name" />
            <Form
              type="text"
              name="message"
              placeholder="Message..."
              rows="-"
            />
            <Form type="email" name="email" placeholder="Email Address" />
            <Form type="text" name="subject" placeholder="Subject" />
            <Form type="submit" name="submit" submit />
          </form>
        </div>
      </div>
    </Layout>
  );
}
