import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Loader from "./Loader";

// let isShowLoader;
export default WrappedComponent => {
    class Wrapper extends Component {
        state = { isLoading: true, duration: 1000, isShowLoader: false };

        componentDidMount = () => {
            this.setTimer()
        };

        componentDidUpdate = prevProps => {
            if (this.props.location !== prevProps.location && this.isShowLoader) {
                this.clearTimer();
                this.setState({ isLoading: true }, () => this.setTimer());
            }
        };

        componentWillMount = () => {
            this.checkSession()
        }

        clearTimer = () => clearTimeout(this.timeout);

        timer = () => this.setState({ isLoading: false }, () => this.clearTimer());

        randomIntFromInterval = (min, max) => {
            const randomNum = Math.floor(Math.random() * (max - min + 1) + min)
            this.setState({
                duration: randomNum
            })
            return randomNum
        }

        setTimer = () => (this.timeout = setTimeout(this.timer, this.randomIntFromInterval(1000, 1200)));

        checkSession = () => {
            const showLoader = window.sessionStorage.getItem('loader_already_shown')
            if (showLoader === 'yes') {
                this.setState({
                    isShowLoader: false,
                })
            } else {
                setTimeout(() => {
                    window.sessionStorage.setItem('loader_already_shown', 'yes')
                }, 1000);
                this.setState({
                    isShowLoader: true,
                })
            }
        }

        render = () => {
            if (!this.state.isShowLoader) return <WrappedComponent {...this.props} />
            return (
                <Fragment>
                    {this.state.isLoading
                        ? <Loader duration={this.state.duration} />
                        : <WrappedComponent {...this.props} />}
                </Fragment>
            )
        };
    }
    return withRouter(Wrapper);
};


// const FnCOm = (params) => {
//     const [isLoading, setIsLoading] = useState(true)   
//     const [duration, setDuration] = useState(1000)   

//     const clearTimer = () => clearTimeout(this.timeout);

//     const timer = () => this.setState({ isLoading: false }, () => this.clearTimer());

//     const randomIntFromInterval = (min, max) => {
//         const randomNum = Math.floor(Math.random() * (max - min + 1) + min)
//         this.setState({
//             duration: randomNum
//         })
//         return randomNum
//     }

//     const setTimer = () => (this.timeout = setTimeout(this.timer, this.randomIntFromInterval(800, 1000)));

//     useEffect(() => {
//         setTimer()
//     })
// }