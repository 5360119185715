


export default function ViewLinks(props) {
    return <div className="flex flex-col xl:flex-row row-2 shrink-0 justify-between mx-5 my-8 gap-3 xl:gap-0">
        <a className="xl:w-64 h-14 mx-2 border-solid shrink-0 duration-150 border-2 border-lime-500 flex justify-center hover:border-lime-700"
        href={props.gitLink}>
            <img src="icons/github.svg" className="h-6 self-center"></img>
            <p className="ml-2 self-center text-white font-bold">View on GitHub</p>
        </a>
        <a className="xl:w-64 h-14 mx-2 border-solid shrink-0 duration-150 border-2 border-lime-500 flex justify-center hover:border-lime-700"
        href={props.dribLink}>
            <img src="icons/dribbble.svg" className="h-7 self-center"></img>
            <p className="ml-2 self-center text-white font-bold">View on Dribbble</p>
        </a>
    </div>
}