import { NavLink, useLocation } from "react-router-dom";

export default function NavigationMobile() {
  const location = useLocation();
  const isActive = (path) => {
    return path === location.pathname;
  };
  return (
    <div className="w-full bg-[#181D23] grid lg:hidden grid-cols-4">
      <NavLink
        to="/"
        className={
          isActive("/")
            ? "bg-[#1D232B] border-b-4 border-[#3EAEEE]"
            : "hover:bg-[#1D232B] duration-150"
        }
      >
        <div className="flex justify-center items-center py-8">
          <img src="/icons/introduction.svg" />
        </div>
      </NavLink>

      <NavLink
        to="/projects"
        className={
          isActive("/projects")
            ? "bg-[#1D232B] border-b-4 border-[#B1DC56]"
            : "hover:bg-[#1D232B] duration-150"
        }
      >
        <div className="flex justify-center items-center py-8">
          <img src="/icons/portfolio.svg" />
        </div>
      </NavLink>

      <NavLink
        to="/press"
        className={
          isActive("/press")
            ? "bg-[#1D232B] border-b-4 border-[#EE533E]"
            : "hover:bg-[#1D232B] duration-150"
        }
      >
        <div className="flex justify-center items-center py-8">
          <img src="/icons/press.svg" />
        </div>
      </NavLink>

      <NavLink
        to="/contact"
        className={
          isActive("/contact")
            ? "bg-[#1D232B] border-b-4 border-[#7B62E2]"
            : "hover:bg-[#1D232B] duration-150"
        }
      >
        <div className="flex justify-center items-center py-8">
          <img src="/icons/contact.svg" />
        </div>
      </NavLink>
    </div>
  );
}
