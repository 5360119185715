import { Link } from "react-router-dom";

const logo = "/img/logo.svg";

const github = "/icons/github.svg";
const instagram = "/icons/instagram.svg";
const linkedin = "/icons/linkedin.svg";
const youtube = "/icons/youtube.svg";

export default function Navbar() {
  return (
    <nav className=" border-y border-border-gray py-4 px-6 md:px-24 flex items-center justify-between">
      <div
        className="inline-block mr-6"
        data-aos="fade-down"
        data-aos-delay="250"
      >
        <Link to="/">
          <img src={logo} className="h-10" />
        </Link>
      </div>

      <div
        className="flex items-center"
        data-aos="fade-down"
        data-aos-delay="300"
      >
        <a href="https://github.com/andyvonde" target="_blank">
          <img src={github} />
        </a>
        <a href="https://www.instagram.com/andyvonde" target="_blank">
          <img src={instagram} className=" mx-4 sm:mx-6 md:mx-12" />
        </a>
        <a href="https://www.linkedin.com/in/andyvonde/" target="_blank">
          <img src={linkedin} />
        </a>
        <a href="https://www.youtube.com/c/AndyKongOfficial/" target="_blank">
          <img src={youtube} className=" ml-4 sm:ml-6 md:ml-12" />
        </a>
      </div>
    </nav>
  );
}
