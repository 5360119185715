

export default function Title(props) {

    return(
        <div className="font-display">
            <p className={`font-bold text-xs sm:text-base mb-4 text-opacity-90 text-${props.color}`}>
                [ *<span className="text-white mx-2">{props.note}</span>]
            </p>
            <p className="text-white font-bold text-4xl sm:text-5xl xl:text-6xl">{props.children}</p>
        </div>
    )
}