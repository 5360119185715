import { useState } from 'react';
import Decorate from './decorate';

export default function Preview(props) {
    const [index, setIndex] = useState(0);

    let listedItems = props.images.map((image, i) => {
        let add;
        if (index === i) add = 'border-2 border-lime-500';
        else add = 'border-slate-600 border hover:border-2';

        let add1;
        if (index === i) add1 = 'bg-lime-500';
        else add1 = 'bg-slate-600';
        return (
            <div
                key={i}
                onClick={((i) => {
                    return () => {
                        setIndex(i);
                    };
                })(i)}
            >
                <div
                    className={
                        'hidden relative xl:block w-24 h-24 mx-2 border-solid cursor-pointer shrink-0 duration-150 ' +
                        add
                    }
                >
                    <div className='h-5'></div>
                    <img className='w-full absolute top-1/2 -translate-y-1/2' src={image} />
                </div>
                <div className={'w-3 h-3 duration-200 xl:hidden ' + add1} />
            </div>
        );
    });
    let size = props.images.length;
    return (
        <div className='m-0 xl:m-5 xl:border-white font-mono p-0 bg-[#181D23] md:bg-transparent mb-16 md:mb-0'>
            <div className='mx-0 xl:mx-2 xl:border-2 xl:border-slate-600 pb-full relative w-full'>
                <img
                    className='w-full absolute top-1/2 -translate-y-1/2'
                    src={props.images[index]}
                />
                <div
                    className='absolute left-0 h-full w-1/2'
                    onClick={() => {
                        setIndex((index - 1 + size) % size);
                    }}
                />
                <div
                    className='absolute right-0 h-full w-1/2'
                    onClick={() => {
                        setIndex((index + 1) % size);
                    }}
                />
                <Decorate />
            </div>
            <div className='xl:border-t-2 border-slate-600 border-dashed h-0 mx-2 my-6 xl:my-10'></div>

            <div className='flex flex-row shrink-0 justify-center gap-4 xl:gap-0 xl:justify-between relative top-8 lg:top-0'>
                {listedItems}
            </div>
        </div>
    );
}
