import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Projects from "./pages/projects";
import Portfolio from "./pages/portfolio";
import Press from "./pages/press";
import "./style.css";
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Wrapper(Home)} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/press" component={Press} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
