
export default function Form(props) {

    let value="";
    if (props.value) value = "cursor-pointer"

    if (props.rows) {
        return <textarea id={props.name} name={props.name} placeholder={props.placeholder} value={props.value}
                 className={`box-border bg-transparent p-5 font-bold text-xl border border-border-gray outline-0
                focus:border-[3px] duration-200 row-span-2 h-auto resize-none ${value}`}/>
    }
    if (props.submit) {
        return <input type={props.type} id={props.name} name={props.name} placeholder={props.placeholder} value="Submit"
                    style={{borderColor: "#7B62E2"}} className={`box-border bg-transparent p-5 font-bold text-xl border-2 outline-0
                    focus:border-[3px] duration-200 cursor-pointer`}/>
    }
    return <input type={props.type} id={props.name} name={props.name} placeholder={props.placeholder}
    className={`box-border bg-transparent p-5 font-bold text-xl border border-border-gray outline-0
                focus:border-[3px] duration-200`}/>
}