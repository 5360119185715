import Navigation from "./navigation";
import NavigationMobile from "./navigationMobile";
import TopBar from "./topbar";

function Layout({ children }) {
  return (
    <div
      style={{ backgroundColor: "#1d232b" }}
      className="flex flex-col min-h-screen"
    >
      <TopBar />
      <NavigationMobile />
      {children}
      <Navigation />
    </div>
  );
}

export default Layout;
