import Lottie from "lottie-web";
import { useEffect, useRef } from "react";
import Button from "../components/button";
import Layout from "../components/Layout";
import Title from "../components/title";

export default function Home() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const lottieContainer = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      animationData: require("../lottie/lf20_vsi4cjsz.json"),
    });
  }, []);
  return (
    <Layout>
      <div className="max-w-base-container mx-auto md:my-16 ">
        <div className="mt-16 grid lg:grid-cols-2 gap-x-16 px-[10%] md:px-[11.5%] lg:px-8">
          {/* LEFT */}
          <div>
            <div className="mb-6">
              <img
                src="/img/trapezoids.png"
                className="w-[100%] block"
                alt="trapezoid img"
              />
            </div>
            <div className="text-purple">
              <Title note="Brief Summary :)">About Me</Title>
            </div>

            <hr className="my-8 border-t-2 border-dashed border-border-gray" />

            <div>
              <h2 className="text-white text-xl font-gold">
                <span className="text-gold">/ *</span> Hey, I'm Andy Kong
              </h2>
              <p className="my-8 text-gray-400 font-lexend text-md">
              A builder and technology enthusiast at heart, currently I serve as the CEO and Founder of Alqen.io, an e-commerce competitor analysis tool that aims to assist sellers. My path wasn't traditional; I'm a self-taught developer who found joy in frontend web development. I often find myself creating workflow automations, not out of a desire for efficiency, but simply because I like making things a bit easier for everyone.
              </p>
              <p className="my-8 text-light text-gray-400 font-lexend  mb-12">
              My interests are vast, spanning from webgl to ethical pentesting. I'm always eager to learn and grow. And if you're ever curious about turning a childhood hobby into a venture, I have a few stories from my Minecraft days.
              </p>
              <Button
                href="/contact"
                text="Get in Touch"
                // eslint-disable-next-line react/style-prop-object
                style="outline"
                borderColor="blue"
                fullWidth
                hiddenSm
              />
            </div>
          </div>

          {/* RIGHT */}
          <div className="mt-0 lg:mt-0 lg:border-l border-border-gray lg:pl-16 items-center flex lg:border-t-0">
            <div className="w-full block" ref={lottieContainer}></div>
          </div>
        </div>

        {/* <div className="flex justify-between mt-16">
                <div>
                    <div className="relative flex items-center">
                        <div className="relative">
                            <img className="" src="/img/button-disabled.svg"/>
                            <img className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" src="/icons/arrow.svg"/>
                        </div>
                    </div>
                </div>
                
                <Link to="/about">
                    <div className="relative flex items-center">
                        <span className="pr-4 text-white text-lg font-mono font-bold">About Me</span>
                        <div className="relative">
                            <img className="" src="/img/button.svg"/>
                            <img className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-180" src="/icons/arrow.svg"/>
                        </div>
                    </div>
                </Link>
            </div> */}
      </div>
    </Layout>
  );
}
