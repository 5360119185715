import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import useSound from "use-sound";
import Hover from "../sound/hover.mp3";

export default function Navigation() {
  const location = useLocation();
  const isActive = (path) => {
    return path === location.pathname;
  };

  const [play] = useSound(Hover);

  return (
    <div className="pb-8">
      <div className="max-w-base-container w-11/12 mx-auto bg-[#181D23] skew-x-6 hidden lg:grid grid-cols-4 border border-border-gray mt-24">
        <NavLink
          to="/"
          className={
            isActive("/")
              ? "bg-[#1D232B] border-t-4 border-[#3EAEEE]"
              : "hover:bg-[#1D232B] duration-75 hover:border-t-4 hover:border-[#3EAEEE]"
          }
        >
          <div
            className="flex -skew-x-6 py-6 px-16 justify-center"
            onMouseEnter={isActive("/") ? null : () => play()}
          >
            <img src="/icons/introduction.svg" alt="introduction page" />
            <span className="font-mono text-lg font-bold pl-3 text-white uppercase">
              Introduction
            </span>
          </div>
        </NavLink>

        <NavLink
          to="/projects"
          className={
            isActive("/projects")
              ? "bg-[#1D232B] border-t-4 border-[#B1DC56]"
              : "hover:bg-[#1D232B] duration-75 hover:border-t-4 hover:border-[#B1DC56] "
          }
        >
          <div
            className="flex -skew-x-6 py-6 px-16 justify-center"
            onMouseEnter={isActive("/projects") ? null : () => play()}
          >
            <img src="/icons/portfolio.svg" alt="portfolio icon" />
            <span className="font-mono text-lg font-bold pl-3 text-white uppercase">
              Portfolio
            </span>
          </div>
        </NavLink>

        <NavLink
          to="/press"
          className={
            isActive("/press")
              ? "bg-[#1D232B] border-t-4 border-[#EE533E]"
              : "hover:bg-[#1D232B] duration-75 hover:border-t-4 hover:border-[#EE533E]"
          }
        >
          <div
            className="flex -skew-x-6 py-6 px-16 justify-center"
            onMouseEnter={isActive("/press") ? null : () => play()}
          >
            <img src="/icons/press.svg" alt="press icon" />
            <span className="font-mono text-lg font-bold pl-3 text-white uppercase">
              Press
            </span>
          </div>
        </NavLink>

        <NavLink
          to="/contact"
          className={
            isActive("/contact")
              ? "bg-[#1D232B] border-t-4 border-[#7B62E2]"
              : "hover:bg-[#1D232B] duration-75 hover:border-t-4 hover:border-[#7B62E2] "
          }
        >
          <div
            className="flex -skew-x-6 py-6 px-16 justify-center"
            onMouseEnter={isActive("/contact") ? null : () => play()}
          >
            <img src="/icons/contact.svg" alt="contact icon" />
            <span className="font-mono text-lg font-bold pl-3 text-white uppercase">
              Contact
            </span>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
