import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Button({
  href,
  text,
  style,
  bgColor,
  fullWidth,
  hiddenSm,
}) {
  return (
    <Link to={href}>
      <motion.button
        className={`
            text-center text-white border-purple px-8 py-4 text-lg font-mono font-bold
            ${style == "outline" ? "border-2" : "bg-bgColor"}
            ${fullWidth && "w-full"}
            ${hiddenSm ? "hidden lg:inline-block" : "inline-block"}
            lg:w-auto hover:opacity-70 duration-200`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => null}
      >
        {text}
      </motion.button>
    </Link>
  );
}
