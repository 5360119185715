import { useState } from "react";
import ColorPallet from "../components/colorPallet";
import Layout from "../components/Layout";
import LogoDesign from "../components/logoDesign";
import NextButton from "../components/nextButton";
import Preview from "../components/preview";
import TechStack from "../components/techStack";
import Title from "../components/title";
import Typography from "../components/typography";
import ViewLinks from "../components/viewLinks";
import "../style.css";

// some fonts are not included

const projects = [
  {
    title: "Project Wifi1",
    comment: "Started 01/25/2021, completed by 01/25/2021",
    logo: "/img/project.png",
    fontTitle: "font-mono",
    fontName: "mono",
    colors: [
      "#00FFFF",
      "#ABCDEF",
      "#FF00FF",
      "#FFFF00",
      "#FFFF00",
      "#FFFF00",
      "#FFFF00",
    ],
    backend: [
      ["Nuxt.js", "/icons/linkedin.svg"],
      ["Nuxt.js", "/icons/linkedin.svg"],
    ],
    frontend: [
      ["Nuxt.js", "/icons/linkedin.svg"],
      ["Nuxt.js", "/icons/linkedin.svg"],
    ],
    gitLink: "",
    dribLink: "",
    images: [
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
    ],
  },
  {
    title: "Project Wifi2",
    comment: "Started 01/25/2021, completed by 01/25/2021",
    logo: "/img/project.png",
    fontTitle: "font-mono",
    fontName: "mono",
    colors: [
      "#00FFFF",
      "#FFFFFF",
      "#FF00FF",
      "#FFFF00",
      "#FFFF00",
      "#FFFF00",
      "#FFFF00",
    ],
    backend: [
      ["Nuxt.js", "/icons/linkedin.svg"],
      ["Nuxt.js", "/icons/linkedin.svg"],
    ],
    frontend: [
      ["Nuxt.js", "/icons/linkedin.svg"],
      ["Nuxt.js", "/icons/linkedin.svg"],
    ],
    gitLink: "",
    dribLink: "",
    images: [
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
    ],
  },
  {
    title: "Project Wifi3",
    comment: "Started 01/25/2021, completed by 01/25/2021",
    logo: "/img/project.png",
    fontTitle: "font-mono",
    fontName: "mono",
    colors: [
      "#00FFFF",
      "#FFFFFF",
      "#FF00FF",
      "#FFFF00",
      "#FFFF00",
      "#FFFF00",
      "#FFFF00",
    ],
    backend: [
      ["Nuxt.js", "/icons/linkedin.svg"],
      ["Nuxt.js", "/icons/linkedin.svg"],
    ],
    frontend: [
      ["Nuxt.js", "/icons/linkedin.svg"],
      ["Nuxt.js", "/icons/linkedin.svg"],
    ],
    gitLink: "",
    dribLink: "",
    images: [
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
      "/img/project.png",
    ],
  },
];

export default function Portfolio() {
  const [index, setIndex] = useState(0);
  let project = projects[index];
  let size = projects.length;

  return (
    <Layout>
      <div className="w-full max-w-base-container flex flex-col-reverse xl:flex-row xl:mx-auto mt-16 items-stretch sm:items-center xl:items-stretch justify-center">
        <div className="mx-2 shrink-0 self-center hidden xl:block">
          <NextButton func={setIndex} num={index} size={size} />
        </div>
        <div className="sm:flex sm:flex-wrap xl:block xl:max-w-lg shrink-0 sm:justify-center">
          <div className="hidden m-5 xl:block mb-12">
            <Title note={project.comment} color="gold">
              <span className="font-display">
                {project.title}
                <span style={{ color: "#B1DC56" }}>.</span>
              </span>
            </Title>
          </div>

          <LogoDesign src={project.logo} />

          <Typography fontTitle={project.fontTitle} font={project.fontName} />

          <ColorPallet colors={project.colors} />

          <TechStack back={project.backend} front={project.frontend} />
        </div>

        <div className="w-0 border-r-2 border-border-gray mr-4 opacity-50 my-4 hidden xl:block"></div>

        <div className="sm:w-2/3 xl:w-auto">
          <Preview images={project.images} />
          <ViewLinks gitLink={project.gitLink} dribLink={project.dribLink} />
          <div className="h-0 border-t-2 border-border-gray opacity-50 mb-12 mt-7 xl:hidden mx-6 sm:-mx-16"></div>
        </div>
        <div className="sm:w-2/3 xl:w-auto xl:hidden mb-12 mx-5">
          <Title note={project.comment} color="gold">
            <span>
              {project.title}
              <span style={{ color: "#B1DC56" }}>.</span>
            </span>
          </Title>
          <div className="flex mt-12 mx-4 items-center">
            <NextButton func={setIndex} num={index} size={size} />
            <div className="ml-auto"></div>
            <p className="text-xl text-white font-bold mr-4">Next Project</p>
            <NextButton func={setIndex} num={index} size={size} right />
          </div>
        </div>
        <div className="mx-2 shrink-0 self-center hidden xl:block">
          <NextButton func={setIndex} num={index} size={size} right />
        </div>
      </div>
    </Layout>
  );
}
