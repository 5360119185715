import { useLocation } from "react-router-dom";
import "./loader.css";

function Loader({ duration }) {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  // This code is for parcentage animation

  // const [count, setCount] = useState(0);
  // const countup = (start, end, time) => {
  //     var i = start;
  //     var interval = setInterval(function () {
  //         if (++i <= end) {
  //             setCount(i);
  //         } else {
  //             clearInterval(interval);
  //         }
  //     }, time / (end - start));
  // };

  // useEffect(() => {
  //     isHomePage && countup(0, 100, duration - 450);
  // }, [isHomePage]);

  return (
    <div className="wrapper">
      <div className="loader_wrapper">
        {/* Parcentage Wrapper --Start-- */}
        {isHomePage && (
          <div className="flex items-center gap-2 w-full">
            {/* Progress Bar --Start-- */}

            {/* <div className='grow h-2 rounded-full overflow-hidden bg-[#dbe3f418]'>
                            <div
                                className='h-full bg-[#5C8DF6] animation_progress_bar'
                                style={{ animationDuration: duration - 20 + 'ms' }}
                            ></div>
                        </div> */}

            {/* Progress Bar --End-- */}
            {/* Parcentage animation start */}

            {/* <span className='text-white font-semibold shrink-0'>{count}%</span> */}

            {/* Parcentage animation end */}
          </div>
        )}
        {/* Parcentage Wrapper --End-- */}
        {/** ${isHomePage ? 'mt-[35px]' : ''} */}
        <div className={`boxes`}>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
