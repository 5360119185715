
export default function Typography(props) {
    return <div className="font-mono m-5 border-white font-mono sm:w-2/5 xl:w-auto">
        <p style={{color: "#3EAEEE"}} className="font-bold text-lg ml-1 mb-5 text-opacity-90">
            &lt;<span className="text-white mx-3 text-xl">Typography</span>&gt;
        </p>
        <p style={{fontFamily: props.font}}>
            <p className="text-white font-bold text-5xl xl:text-6xl">{props.fontTitle}</p>
            <p className="text-white font-bold text-xl xl:text-2xl mt-4 xl:mt-5 font-light text-opacity-70">Aa&nbsp; Bb&nbsp;  Cc&nbsp;  Dd&nbsp;  Ee&nbsp;  Ff&nbsp;  Gg&nbsp;  Hh&nbsp;  Ii&nbsp;  Jj&nbsp;  Kk&nbsp; Ll&nbsp;  Mm&nbsp;  Nn&nbsp;  Oo&nbsp;  Pp&nbsp;  Qq&nbsp;  Rr&nbsp;  Ss&nbsp;  Tt&nbsp;  Uu&nbsp; Vv&nbsp; Ww&nbsp; Xx&nbsp; Yy&nbsp; Zz</p>
        </p>
    </div>
}