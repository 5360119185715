
export default function NextButton(props) {
    let add = ""
    let func

    if (props.right) {
        add = "rotate-180"
        func = () => {props.func((props.num + 1) % props.size)}
    }

    else {
        func = () => {props.func((props.num - 1 + props.size) % props.size)}
    }

    return(
    <div className="relative cursor-pointer" onClick={func}>
        <img className="" src="/img/button.svg"/>
        <img className={"absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 " + add} src="/icons/arrow.svg"/>
    </div>
    )
}