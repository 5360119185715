export default function TechStack(props) {
    let frontList = props.front.map((front, i) => (
        <div
            key={i}
            style={{ backgroundColor: '#4A535F' }}
            className='h-14 mx-1 xl:ml-0 xl:mr-12 my-2 flex justify-between px-5'
        >
            <p className='self-center text-white font-bold text-lg'>{front[0]}</p>
            <img className='py-2' src={front[1]} />
        </div>
    ));

    let backList = props.front.map((front, index) => (
        <div
            key={index}
            style={{ backgroundColor: '#4A535F' }}
            className='h-14 mx-1 xl:ml-0 xl:mr-12 my-2 flex justify-between px-5'
        >
            <p className='self-center text-white font-bold text-lg'>{front[0]}</p>
            <img className='py-2' src={front[1]} />
        </div>
    ));

    return (
        <div className='font-mono m-5 border-white sm:w-2/5 xl:w-auto'>
            <p style={{ color: '#B1DC56' }} className='font-bold text-lg ml-1 mb-5 text-opacity-90'>
                &lt;<span className='text-white mx-3 text-xl'>Tech Stacks</span>&gt;
            </p>
            <p className='text-white font-bold text-xl'>Front-End</p>
            <div>{frontList}</div>
            <p className='text-white font-bold text-xl mt-5'>Back-End</p>
            <div>{backList}</div>
        </div>
    );
}
